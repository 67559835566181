/*phaser chart start*/
/*when create new comaprison, destroy the chart prevent memory leak, also clear the canvas innerhtml content*/
function destroyChart() {
    if (chartCanvas.chart) {
        chartCanvas.chart.destroy();
        dummyChart.destroy();
        chartCanvas.chart = null;
        dummyChart = null;
        showClaims = false;
        if ($('#onoff-input').is(':checked')) {
            $('#onoff-input').prop('checked', false);
            $('.alert-light span').toggleClass('selected');
        }

        $('#claims-tabs .tab').removeClass('active');
        $('#claims-tabs:first-child').addClass('active');
        $('#claims-tabs-content div').removeClass('active');
        $('#claims-tabs-content:nth-child(1)').addClass('active');

        $('#claims-tabs-wrapper').slideUp();
        $('#chart-canvas canvas').remove();
        $('#dummy-canvas canvas').remove();
        $('#tab-img1').removeClass();
        $('#tab-img2').removeClass();
        $('#tab-img3').removeClass();

        $highLevel.show();
        $midLevel.show();
        $lowLevel.show();
        $highLevelLabel.show();
        $midLevelLabel.show();
        $lowLevelLabel.show();
        $dotHigh.hide();
        $dotMid.hide();
        $dotLow.hide();
        $claim1.hide();
        $claim2.hide();
        $claim3.hide();
        $claimsXlabel.hide();
    }
}

window.PhaserGlobal = { disableWebAudio: true };
function CreateChart(canvasDOM) {
    var thisChart = this;
    var pt = 55, pb = 70, pl = 30, pr = 10;
    var canvasWidth = 500, canvasHeight = 375, chartHeight = canvasHeight - pt - pb;
    var chartBgSprite, xAxis, yAxis, yAxisLabel, chartTitle;
    var baseLimit = 0;
    var barHeight = 220, barWidthMax = 100, barWidthMin = 90; //100-90
    var barGapMax = 25;

    var highLimit, midLimitRatio, lowLimitRatio;
    var highLimitSprite, midLimitSprite, lowLimitSprite;
    var dotHighLimitSprite, dotMidLimitSprite, dotLowLimitSprite;
    var highLimitLabel, midLimitLabel, lowLimitLabel;
    var highLimitValueLabel, midLimitValueLabel, lowLimitValueLabel;
    var dottledLine1, dottledLine2, dottledLine3, dottledLine4;

    var tweenHigh, tweenMid, tweenLow;
    var tweenDotHigh, tweenDotMid, tweenDotLow;
    var tweenHighDown, tweenMidDown, tweenLowDown;
    var tweenDotHighDown, tweenDotMidDown, tweenDotLowDown;
    var tweenBarGroup0, tweenDotGroup0;
    var claimValue1, claimValue2, claimValue3;
    var claimValue1YPos, claimValue2YPos, claimValue3YPos;
    var tweenClaim1, tweenClaim2, tweenClaim3;
    var tweenClaim1Down, tweenClaim2Down, tweenClaim3Down;
    var ctArr = [], ccArr = [];
    var firstTime = true;

    var lmhLine, barGroup, dotGroup;

    //init 620x550 canvas, but respond to parent div dimension using Phaser.ScaleManager.RESIZE;
    var config = {
        "width": $('#chart-canvas').width(),
        "height": canvasHeight,
        "renderer": Phaser.CANVAS,
        "parent": canvasDOM,
        "resolution": window.devicePixelRatio,
        "state": { preload: preload, create: create, update: update, render: render },
        "transparent": true
    };

    var maxLimit, baseUnit = 0;
    function mapData(limit) {
        maxLimit = cyberResult.highEndRisk;
        if (maxLimit < 1000000) {
            baseUnit = 1000;
        } else {
            baseUnit = 1000000;
        }
        var tempYPos = Math.round(canvasHeight - pb - ((limit / baseUnit) - baseLimit) / ((maxLimit / baseUnit) - baseLimit) * barHeight);
        if (tempYPos <= canvasHeight - pb - chartHeight) {
            tempYPos = canvasHeight - pb - chartHeight;
        }
        return tempYPos;
    }
    midLimitRatio = cyberResult.midRangeRisk / cyberResult.highEndRisk;
    lowLimitRatio = cyberResult.lowEndRisk / cyberResult.highEndRisk;

    if (canvasDOM == 'dummy-canvas') {
        dummyChart = new Phaser.Game(canvasWidth, canvasHeight, Phaser.CANVAS, canvasDOM, {
            preload: preload, create: create, update: update, render: render
        }, true);
    } else {
        this.chart = new Phaser.Game(config);
    }

    function getBarWidthRatio(w) {
        var m = (barWidthMax - barWidthMin) / (canvasWidth - 375);
        var b = barWidthMax - m * canvasWidth;
        return (m * w + b) / barWidthMax;
    }

    function preload() {
        this.load.image('chartBg', 'images/chart_bg.jpg');
        this.load.image('solidLine', 'images/solidLine.png');
        //BHR - 20200219 - Loading images manually because the art-department's re-color of the gal-chart.png and my updates of the accompanying JSON were breaking the chart.
        //this.load.atlasJSONHash('gal-chart-sprite', 'images/gal-chart.png', 'images/gal-chart.json');

        this.load.image('claim-1', 'images/claim-1.png');
        this.load.image('claim-2', 'images/claim-2.png');
        this.load.image('claim-3', 'images/claim-3.png');
        this.load.image('dotted-line', 'images/dotted-line.png');
        this.load.image('high', 'images/high.png');
        this.load.image('highLimit', 'images/highLimit.jpg');
        this.load.image('low', 'images/low.png');
        this.load.image('lowLimit', 'images/lowLimit.jpg');
        this.load.image('med', 'images/med.png');
        this.load.image('midLimit', 'images/midLimit.jpg');
    }

    function create() {
        Phaser.Canvas.setTouchAction(this.game.canvas, "auto");
        this.scale.scaleMode = Phaser.ScaleManager.RESIZE;
        this.input.touch.preventDefault = false;

        //chart-canvas not need include bg, use dom bg
        if (canvasDOM !== 'chart-canvas') {
            chartBgSprite = this.add.sprite(this.world.centerX, this.world.height, 'chartBg'); //draw bg
            chartBgSprite.anchor.setTo(0.5, 1);
        }

        highLimit = barHeight;
        //offset 5px up to compensate sprite with label
        claimValue1YPos = mapData(cyberResult.claims[0].totalIncurred) - 8;
        claimValue2YPos = mapData(cyberResult.claims[1].totalIncurred) - 8;
        claimValue3YPos = mapData(cyberResult.claims[2].totalIncurred) - 8;

        var chartTitleStyle = { font: '16px Arial', fontWeight: 'bold', fill: 'white', align: 'center', wordWrap: true, wordWrapWidth: 300 };
        chartTitle = this.add.text(this.world.centerX, 25, 'Quantifying Your Cyber Risk', chartTitleStyle);
        chartTitle.anchor.setTo(0.5, 0);
        if (canvasDOM == 'chart-canvas') {
            chartTitle.alpha = 0;
        }

        xAxis = this.add.sprite(pl, this.world.height - pb, 'solidLine'); //draw base line
        //xAxis = this.add.sprite(pl, this.world.height - pb, 'gal-chart-sprite', 'solidLine.png'); //draw base line
        xAxis.width = this.world.width - pr - pl;
        yAxis = this.add.graphics(0, 0);
        yAxis.beginFill(0xffffff);
        yAxis.drawRect(pl, pt, 2, chartHeight);
        yAxis.endFill();

        //for high, mid, low vertical bar
        var chartCenterX = Math.floor((this.world.width - pl - pr) / 2 + pl);
        barGroup = this.add.group();
        lowLimitSprite = this.add.sprite(chartCenterX - barWidthMax - barGapMax, this.world.height - pb, 'lowLimit');
        midLimitSprite = this.add.sprite(chartCenterX, this.world.height - pb, 'midLimit');
        highLimitSprite = this.add.sprite(chartCenterX + barWidthMax + barGapMax, this.world.height - pb, 'highLimit');
        midLimitSprite.scale.y = midLimitRatio;
        lowLimitSprite.scale.y = lowLimitRatio;
        highLimitSprite.anchor.setTo(0.5, 1);
        midLimitSprite.anchor.setTo(0.5, 1);
        lowLimitSprite.anchor.setTo(0.5, 1);

        /* xAxis label */
        var xAxisLabelStyle = { font: '14px Arial', fill: '#F3F4F4', align: 'center', wordWrap: true, wordWrapWidth: 150 };
        var limitValueLabelStyle = { font: '20px Arial', fill: '#F3F4F4', align: 'center', wordWrap: true, wordWrapWidth: 150 };
        if (canvasDOM !== 'chart-canvas') {
            highLimitLabel = this.add.text(Math.floor(chartCenterX + (barWidthMax + barGapMax)), Math.floor(this.world.height - pb + 10), 'High-level', xAxisLabelStyle);
            midLimitLabel = this.add.text(Math.floor(chartCenterX), Math.floor(this.world.height - pb + 10), 'Mid-level', xAxisLabelStyle);
            lowLimitLabel = this.add.text(Math.floor(chartCenterX - (barWidthMax + barGapMax)), Math.floor(this.world.height - pb + 10), 'Low-level', xAxisLabelStyle);
            ctArr.push.apply(ctArr, [highLimitLabel, midLimitLabel, lowLimitLabel]);

            yAxisLabel = this.add.text(18, this.world.height - pb - chartHeight / 2, 'Risk', xAxisLabelStyle);
            yAxisLabel.anchor.setTo(0.5);
            yAxisLabel.angle = -90;

            /* high, mid, low value label at the top of the bar*/
            highLimitValueLabel = this.add.text(Math.floor(chartCenterX + (barWidthMax + barGapMax)), Math.floor(this.world.height - pb - highLimitSprite.height + 10), highCurrency, limitValueLabelStyle);
            midLimitValueLabel = this.add.text(Math.floor(chartCenterX), Math.floor(this.world.height - pb - midLimitSprite.height + 10), midCurrency, limitValueLabelStyle);
            lowLimitValueLabel = this.add.text(Math.floor(chartCenterX - (barWidthMax + barGapMax)), Math.floor(this.world.height - pb - lowLimitSprite.height + 10), lowCurrency, limitValueLabelStyle);
            ctArr.push.apply(ctArr, [highLimitValueLabel, midLimitValueLabel, lowLimitValueLabel]);
        }

        if (canvasDOM == 'chart-canvas') {
        /* group all sprite for bar version */
            barGroup.add(lowLimitSprite);
            barGroup.add(midLimitSprite);
            barGroup.add(highLimitSprite);

            if (canvasDOM == 'chart-canvas') {
                dottledLine1 = this.add.sprite(Math.floor(this.world.width / 5), 55, 'dotted-line');
                dottledLine2 = this.add.sprite(Math.floor(this.world.width / 5 * 2), 55, 'dotted-line');
                dottledLine3 = this.add.sprite(Math.floor(this.world.width / 5 * 3), 55, 'dotted-line');
                dottledLine4 = this.add.sprite(Math.floor(this.world.width / 5 * 4), 55, 'dotted-line');
                ctArr.push.apply(ctArr, [dottledLine1, dottledLine2, dottledLine3, dottledLine4]);
            }

            /* three dot hml sprite*/
            dotLowLimitSprite = this.add.sprite(Math.floor(this.world.width / 5), this.world.height - pb, 'low');
            dotMidLimitSprite = this.add.sprite(Math.floor(this.world.width / 5), this.world.height - pb, 'med');
            dotHighLimitSprite = this.add.sprite(Math.floor(this.world.width / 5), this.world.height - pb, 'high');
            ccArr.push.apply(ccArr, [dotHighLimitSprite, dotMidLimitSprite, dotLowLimitSprite]);
            dotLowLimitSprite.alpha = dotMidLimitSprite.alpha = dotHighLimitSprite.alpha = 0;

            /* three claim result*/
            claimValue1 = this.add.sprite(Math.floor(this.world.width / 5 * 2), this.world.height - pb, 'claim-1');
            claimValue2 = this.add.sprite(Math.floor(this.world.width / 5 * 3), this.world.height - pb, 'claim-2');
            claimValue3 = this.add.sprite(Math.floor(this.world.width / 5 * 4), this.world.height - pb, 'claim-3');
            ccArr.push.apply(ccArr, [claimValue1, claimValue2, claimValue3]);
            claimValue1.alpha = claimValue2.alpha = claimValue3.alpha = 0;

            dotGroup = this.add.group();
            dotGroup.alpha = 0;

            lmhLine = this.add.graphics(0, 0);

            dotGroup.add(dottledLine1);
            dotGroup.add(dottledLine2);
            dotGroup.add(dottledLine3);
            dotGroup.add(dottledLine4);
            dotGroup.add(lmhLine);
            dotGroup.add(dotLowLimitSprite);
            dotGroup.add(dotMidLimitSprite);
            dotGroup.add(dotHighLimitSprite);
            dotGroup.add(claimValue1);
            dotGroup.add(claimValue2);
            dotGroup.add(claimValue3);
            dotGroup.alpha = 0;

            tweenDotHigh = this.add.tween(dotHighLimitSprite);
            tweenDotMid = this.add.tween(dotMidLimitSprite);
            tweenDotLow = this.add.tween(dotLowLimitSprite);
            tweenDotHigh.to({ y: mapData(cyberResult.highEndRisk) }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenDotMid.to({ y: mapData(cyberResult.midRangeRisk) }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenDotLow.to({ y: mapData(cyberResult.lowEndRisk) }, 300, Phaser.Easing.Linear.easeInOut, false);

            tweenClaim1 = this.add.tween(claimValue1);
            tweenClaim2 = this.add.tween(claimValue2);
            tweenClaim3 = this.add.tween(claimValue3);
            tweenClaim1.to({ y: claimValue1YPos }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenClaim2.to({ y: claimValue2YPos }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenClaim3.to({ y: claimValue3YPos }, 300, Phaser.Easing.Linear.easeInOut, false);

            highLimitSprite.scale.y = midLimitSprite.scale.y = lowLimitSprite.scale.y = 0.13;
            tweenHigh = this.add.tween(highLimitSprite.scale);
            tweenMid = this.add.tween(midLimitSprite.scale);
            tweenLow = this.add.tween(lowLimitSprite.scale);
            tweenHigh.to({ y: 1 }, 300, Phaser.Easing.Linear.easeInOut, false, 400);
            tweenMid.to({ y: midLimitRatio }, 300, Phaser.Easing.Linear.easeInOut, false, 200);
            tweenLow.to({ y: lowLimitRatio }, 300, Phaser.Easing.Linear.easeInOut, false);

            tweenHighDown = this.add.tween(highLimitSprite.scale);
            tweenMidDown = this.add.tween(midLimitSprite.scale);
            tweenLowDown = this.add.tween(lowLimitSprite.scale);
            tweenHighDown.to({ y: 0.13 }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenMidDown.to({ y: 0.13 }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenLowDown.to({ y: 0.13 }, 300, Phaser.Easing.Linear.easeInOut, false);

            tweenDotHighDown = this.add.tween(dotHighLimitSprite);
            tweenDotMidDown = this.add.tween(dotMidLimitSprite);
            tweenDotLowDown = this.add.tween(dotLowLimitSprite);
            tweenDotHighDown.to({ y: this.world.height - pb }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenDotMidDown.to({ y: this.world.height - pb }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenDotLowDown.to({ y: this.world.height - pb }, 300, Phaser.Easing.Linear.easeInOut, false);

            tweenClaim1Down = this.add.tween(claimValue1);
            tweenClaim2Down = this.add.tween(claimValue2);
            tweenClaim3Down = this.add.tween(claimValue3);
            tweenClaim1Down.to({ y: this.world.height - pb }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenClaim2Down.to({ y: this.world.height - pb }, 300, Phaser.Easing.Linear.easeInOut, false);
            tweenClaim3Down.to({ y: this.world.height - pb }, 300, Phaser.Easing.Linear.easeInOut, false);

            tweenBarGroup0 = this.add.tween(barGroup);
            tweenBarGroup0.to({ alpha: 0 }, 300, Phaser.Easing.Linear.easeInOut, false, 300);
            tweenBarGroup0.onComplete.add(barGroup0Complete, this);

            tweenDotGroup0 = this.add.tween(dotGroup);
            tweenDotGroup0.to({ alpha: 0 }, 300, Phaser.Easing.Linear.easeInOut, false, 300);
            tweenDotGroup0.onComplete.add(dotGroup0Complete, this);

            //thisChart.tweenBar();
        }

        setSpriteAnchor(ctArr, 'ct');
        setSpriteAnchor(ccArr, 'cc');
    }

    function update() {
        if (canvasDOM == 'chart-canvas') {
            if (firstTime == true) {
                firstTime = false;
                thisChart.tweenBar();
            }
            if (this.world.width > 319) {
                xAxis.width = this.world.width - pr - pl;
                chartTitle.x = this.world.centerX;

                var currentBarWidthRatio = getBarWidthRatio(this.world.width);
                var chartCenterX = Math.floor((this.world.width - pr - pl) / 2 + pl);
                var gapOffset = barGapMax * currentBarWidthRatio;
                if (gapOffset > 15) {
                    gapOffset = 15;
                }
                highLimitSprite.scale.x = midLimitSprite.scale.x = lowLimitSprite.scale.x = currentBarWidthRatio;
                lowLimitSprite.x = chartCenterX - currentBarWidthRatio * barWidthMax - gapOffset;
                midLimitSprite.x = chartCenterX;
                highLimitSprite.x = chartCenterX + currentBarWidthRatio * barWidthMax + gapOffset;
                $('#high-level').css('left', chartCenterX + currentBarWidthRatio * barWidthMax + gapOffset + 'px');
                $('#mid-level').css('left', chartCenterX + 'px');
                $('#low-level').css('left', chartCenterX - currentBarWidthRatio * barWidthMax - gapOffset + 'px');

                $('#high-level-label').css({
                    'left': chartCenterX + currentBarWidthRatio * barWidthMax + gapOffset + 'px',
                    'top': Math.floor(this.world.height - pb - highLimitSprite.height + 5) + 'px'
                });
                $('#mid-level-label').css({
                    'left': chartCenterX + 'px',
                    'top': Math.floor(this.world.height - pb - midLimitSprite.height + 5) + 'px'
                });
                $('#low-level-label').css({
                    'left': chartCenterX - currentBarWidthRatio * barWidthMax - gapOffset + 'px',
                    'top': Math.floor(this.world.height - pb - lowLimitSprite.height + 5) + 'px'
                });

                $('#dot-high').css({
                    'left': Math.floor(this.world.width / 5) + 'px',
                    'top': dotHighLimitSprite.y + 'px',
                    'z-index': '3'
                });
                $('#dot-mid').css({
                    'left': Math.floor(this.world.width / 5) + 'px',
                    'top': dotMidLimitSprite.y + 'px',
                    'z-index': '2'
                });
                $('#dot-low').css({
                    'left': Math.floor(this.world.width / 5) + 'px',
                    'top': dotLowLimitSprite.y + 'px',
                    'z-index': '1'
                });

                $('#claim-1').css({
                    'left': Math.floor(this.world.width / 5 * 2) + 'px',
                    'top': claimValue1.y + 'px'
                });
                $('#claim-1 > .white-circle').css({
                    'background-color': (claimValue1.y < dotHighLimitSprite.y) ? '#FF5A37' : '#FFFFFF'
                });
                $('#claim-2').css({
                    'left': Math.floor(this.world.width / 5 * 3) + 'px',
                    'top': claimValue2.y + 'px'
                });
                $('#claim-2 > .white-circle').css({
                    'background-color': (claimValue2.y < dotHighLimitSprite.y) ? '#FF5A37' : '#FFFFFF'
                });
                $('#claim-3').css({
                    'left': Math.floor(this.world.width / 5 * 4) + 'px',
                    'top': claimValue3.y + 'px'
                });
                $('#claim-3 > .white-circle').css({
                    'background-color': (claimValue3.y < dotHighLimitSprite.y) ? '#FF5A37' : '#FFFFFF'
                });

                drawLMHLine(this);

                dotHighLimitSprite.x = dotMidLimitSprite.x = dotLowLimitSprite.x = dottledLine1.x = this.world.width / 5;
                claimValue1.x = dottledLine2.x = this.world.width / 5 * 2;
                claimValue2.x = dottledLine3.x = this.world.width / 5 * 3;
                claimValue3.x = dottledLine4.x = this.world.width / 5 * 4;
            }
        }
    }

    function render() { }

    /* make sprite element in ctArr anchor to center top position  */
    function setSpriteAnchor(arr, ps) {
        arr.forEach(function (sprite) {
            if (ps == 'ct') {
                sprite.anchor.setTo(0.5, 0);
            } else if (ps == 'cc') {
                sprite.anchor.setTo(0.5);
            }
        });
    }

    function drawLMHLine(chart) {
        lmhLine.clear();
        lmhLine.beginFill(0xa4c8e1);
        lmhLine.drawRect(pl + 2, dotLowLimitSprite.y, chart.world.width - pl - pr, 1);
        lmhLine.beginFill(0x6facde);
        lmhLine.drawRect(pl + 2, dotMidLimitSprite.y, chart.world.width - pl - pr, 1);
        lmhLine.beginFill(0x0075bc);
        lmhLine.drawRect(pl + 2, dotHighLimitSprite.y, chart.world.width - pl - pr, 1);
        lmhLine.endFill();
    }

    function barGroup0Complete() {
        dotGroup.alpha = 1;

        dotHighLimitSprite.y = dotMidLimitSprite.y = dotLowLimitSprite.y = canvasHeight - pb - 25;
        tweenDotLow.start();
        tweenDotMid.start();
        tweenDotHigh.start();


        claimValue1.y = claimValue2.y = claimValue3.y = canvasHeight - pb - 25;
        tweenClaim1.start();
        tweenClaim2.start();
        tweenClaim3.start();
        //isToggleAnimating = false;
    }

    function dotGroup0Complete() {
        barGroup.alpha = 1;
        thisChart.tweenBar();
        //isToggleAnimating = false;
    }

    thisChart.tweenBar = function () {
        highLimitSprite.scale.y = midLimitSprite.scale.y = lowLimitSprite.scale.y = 0.13;
        tweenLow.start();
        tweenMid.start();
        tweenHigh.start();
    };

    thisChart.tweenBarDown = function () {
        tweenLowDown.start();
        tweenMidDown.start();
        tweenHighDown.start();
        tweenBarGroup0.start();
    };

    thisChart.tweenDotDown = function () {
        tweenClaim1Down.start();
        tweenClaim2Down.start();
        tweenClaim3Down.start();
        tweenDotLowDown.start();
        tweenDotMidDown.start();
        tweenDotHighDown.start();
        tweenDotGroup0.start();
    };
}
/*phaser chart end*/

function showHideBarDot() {
    if (showClaims == true) {
        chartCanvas.tweenBarDown();

        $lowLevel.fadeOut(300);
        $midLevel.fadeOut(300);
        $highLevel.fadeOut(300);
        $lowLevelLabel.fadeOut(300);
        $midLevelLabel.fadeOut(300);
        $highLevelLabel.fadeOut(300);

        $dotLow.delay(300).fadeIn(300);
        $dotMid.delay(300).fadeIn(300);
        $dotHigh.delay(300).fadeIn(300);
        $claim1.delay(300).fadeIn(300);
        $claim2.delay(300).fadeIn(300);
        $claim3.delay(300).fadeIn(300);
        $claimsXlabel.delay(300).fadeIn(300);
    } else {
        chartCanvas.tweenDotDown();

        $dotLow.delay(300).fadeOut(300);
        $dotMid.delay(300).fadeOut(300);
        $dotHigh.delay(300).fadeOut(300);
        $claim1.delay(300).fadeOut(300);
        $claim2.delay(300).fadeOut(300);
        $claim3.delay(300).fadeOut(300);
        $claimsXlabel.delay(300).fadeOut(300);

        $lowLevel.delay(300).fadeIn(300);
        $midLevel.delay(300).fadeIn(300);
        $highLevel.delay(300).fadeIn(300);
        $lowLevelLabel.delay(400).fadeIn(300);
        $midLevelLabel.delay(600).fadeIn(300);
        $highLevelLabel.delay(800).fadeIn(300);
    }
}