var baseAPIURL = "#{ApiURL}";
if (~baseAPIURL.indexOf("#{"))
    baseAPIURL = "https://localhost:44331";
//baseAPIURL = "https://ggbusudtdev-api.ajgdev.com";


function convertToCurrency(num, digits, prefix, baseI) {
    var numToM, base;
    base = baseI != undefined ? baseI : (num < 1000000 ? 1000 : 1000000);
    numToM = num / base;
    if (base === 1) {
        return prefix + numToM.toCurrency(digits);
    } else if (base === 1000) {
        if (numToM < 1000) {
            return prefix + numToM.toCurrency(digits) + "K";
        } else {
            var numToB = numToM / 1000;
            return prefix + numToB.toCurrency(digits) + "M";
        }
    }
    else {
        if (numToM < 1000) {
            return prefix + numToM.toCurrency(digits) + "M";
        } else {
            var numToB = numToM / 1000;
            return prefix + numToB.toCurrency(digits) + "B";
        }
    }
}

function addReadMore(str, charLength, index) {
    //trim the string to the maximum length
    if (str.length > 200) {
        var trimmedString = str.substr(0, charLength);

        //re-trim if we are in the middle of a word
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        return trimmedString + '...<span class="read-more" data-ref="' + index + '">[read more]</span>';
    } else {
        return str;
    }
}

var sessionId = null, requestId = 0;
/*json format to send to server*/
var dataToSend = {
    'sessionId': 0,
    'industryId': 0,
    'industryName': '',
    'revenue': 0,
    'employeeCount': 0,
    'locationCount': 0,
    'email': '',
    'firstName': '',
    'lastName': '',
    'company': '',
    'agentEmail': ''
};

var page = {
    contact: 0,
    industry: 1,
    annualSale: 2,
    numEmployees: 3,
    numLocations: 4,
    report: 5
};


var industryArray, cyberResult, emailIsValid;
var lowCurrency, midCurrency, highCurrency;

var chartCanvas, dummyChart, showClaims;
var $highLevel, $midLevel, $lowLevel;
var $highLevelLabel, $midLevelLabel, $lowLevelLabel;
var $dotHigh, $dotMid, $dotLow;
var $claim1, $claim2, $claim3;
var $claimsXlabel;


(function ($) {
    $(document).ready(function () {

        //Setting copyright date throughout.
        $(".copy-right").text("\u00A9  Gallagher 1999 - " + new Date().getFullYear());

        //Configure Toastr
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "15000",
            "extendedTimeOut": "5000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

        //init app
        var selectedIndustry = {};

        $highLevel = $('#high-level'); $midLevel = $('#mid-level'); $lowLevel = $('#low-level');
        $highLevelLabel = $('#high-level-label'); $midLevelLabel = $('#mid-level-label'); $lowLevelLabel = $('#low-level-label');
        $dotHigh = $('#dot-high'); $dotMid = $('#dot-mid'); $dotLow = $('#dot-low');
        $claim1 = $('#claim-1'); $claim2 = $('#claim-2'); $claim3 = $('#claim-3');
        $claimsXlabel = $('#claims-xlabel');

        //mask policy limit to currency
        $('#annual-sales').mask('#,##0', { reverse: true });
        $('#num-employees').mask('#,##0', { reverse: true });
        $('#num-locations').mask('#,##0', { reverse: true });


        $.ajax({
            url: baseAPIURL + "/api/CyberRisk/getCyberIndustries",
            type: 'GET',
            dataType: 'json',
            success: function (response) {
                industryArray = response;
                generateIndustryListGroup();
            },
            error: function (xhr) {
                toastr.error("Please reload the page to try again. If you keep seeing this error please contact a Gallagher Consultant today.", "We encountered an error");
                console.log(xhr);
            },
            complete: function (xhr, status) {
                //console.log('complete')
            }
        });

        /*build step 1 to 4 slide and final report slide*/
        function buildSlide() {
            if ($('#step-search').length > 0) {
                $('#step-search').slick({
                    initialSlide: 0,
                    dots: false,
                    infinite: false,
                    arrows: false,
                    adaptiveHeight: true,
                    speed: 300,
                    swipe: false,
                    focusOnSelect: false
                });
                $('#step-search').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                    $('#nav-step-wrapper').children().each(function (idx, val) {
                        idx <= nextSlide ? $(this).addClass('active') : $(this).removeClass('active');
                    });
                    if (nextSlide == 5) {
                        $('#nav-step-wrapper').css('display', 'none');
                        $('#step-wrapper').addClass('report');
                    } else {
                        $('#nav-step-wrapper').css('display', 'flex');
                        $('#step-wrapper').removeClass('report');
                    }
                    $('html, body').animate({
                        scrollTop: 0
                    }, 200);
                });
            }
        }

        $('#btn-back').on('click', function () {
            $('#step-search').slick('slickGoTo', 0, false);
        });
        /*slide end*/

        /*step intro*/
        $('#btn-begin').on('click', function () {
            $('#intro-wrapper').fadeOut();
            $('#step-wrapper').fadeIn(function () {
                $('#nav-step-wrapper .nav-step:nth-child(1)').addClass('active');
                $('html, body').animate({
                    scrollTop: 0
                }, 200);
            });
            $('#step-search').slick('setPosition');
        });

        /*validate email on blur */
        $("#email").blur(function () {
            validateEmail();
        });

        $("#first").blur(function () {
            validateFirstName();
        });

        $("#last").blur(function () {
            validateLastName();
        });

        $("#company").blur(function () {
            validateCompany();
        });

        $("#agent-email").blur(function () {
            validateAgent();
        });

        $("#annual-sales").blur(function () {
            validateAnnualSales();
        });

        $("#num-employees").blur(function () {
            validateNumberOfEmployees();
        });

        $("#num-locations").blur(function () {
            validateNumberOfLocations();
        })

        $('#btn-to-industry').on('click', function () {
            var timeout = setTimeout(function () { $("#email").blur(); }, 500)

            clearTimeout(timeout);
            
            if ($('#first').val().trim().length > 0 && $('#last').val().trim().length > 0 && $('#company').val().trim().length > 0 && $('#email').val().trim().length > 0 && emailIsValid) {
                toastr.remove();
                $('#step-search').slick('slickGoTo', page.industry, false);
            } else {
                toastr.error("Please enter all required fields", "Missing Fields");
                //alert('Please enter all required fields');
            }
        });
        $('#btn-back-intro').on('click', function () {
            $('#step-wrapper').fadeOut();
            $('#intro-wrapper').fadeIn(function () {
                $('html, body').animate({
                    scrollTop: 0
                }, 200);
            });
        });

        /*step industry*/
        function generateIndustryListGroup() {
            var tempStr = '';
            for (var i = 0; i < industryArray.length; i++) {
                tempStr += '<li class="list-group-item" data-refid="' + i + '"><span class="gal-' + industryArray[i].cssClass + '"></span><span>' + industryArray[i].name + '</span></li>';
            }

            if (industryArray.length % 2 != 0) {
                tempStr += '<li class="last-child"></li>';
            }

            $('#industry-list').html(tempStr);
        }

        $('#industry-list').on('click', 'li.list-group-item', function () {
            var refId = parseInt($(this).data('refid'));
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
            if (selectedIndustry.name != industryArray[refId].name) {
                selectedIndustry = industryArray[refId];
                $('.industry-title').text(selectedIndustry.name);
            }
            //console.log(selectedIndustry);
            $('#step-search').slick('slickGoTo', page.annualSale, false);
        });
        $('#btn-back-contact').on('click', function () {
            $('#step-search').slick('slickGoTo', page.contact, false);
        });

        /*step annual sales*/
        $('#btn-back-industry').on('click', function () {
            $('#step-search').slick('slickGoTo', page.industry, false);
        });
        $('#btn-number-employees').on('click', function () {
            if (annualSalesIsValid) {
                toastr.remove();
                $('#step-search').slick('slickGoTo', page.numEmployees, false);
            } else {
                toastr.error("Please enter all required fields", "Missing Fields");
                //alert('Please enter all required fields');
            }
        });

        /*step num employees*/
        $('#btn-back-annualsales').on('click', function () {
            $('#step-search').slick('slickGoTo', page.annualSale, false);
        });
        $('#btn-number-locations').on('click', function () {
            if (numberOfEmployeesIsValid) {
                toastr.remove();
                $('#step-search').slick('slickGoTo', page.numLocations, false);
            } else {
                toastr.error("Please enter all required fields", "Missing Fields");
            }
        });

        /*step num locations*/
        $('#btn-back-employees').on('click', function () {
            $('#step-search').slick('slickGoTo', page.numEmployees, false)
        });

        $('#btn-generate-report').on('click', function () {
            if (numberOfLocationsIsValid) {
                toastr.remove();
                $('#loading-wrapper').css('display', 'flex');
                prepareData();
                //call to server to retrieve data back from powerBI
                $.ajax({
                    url: baseAPIURL + '/api/CyberRisk/calculateCyberRisk',
                    type: 'POST',
                    data: JSON.stringify(dataToSend),
                    dataType: 'json',
                    contentType: "application/json",
                    success: function (response) {
                        cyberResult = response;
                        $('#loading-wrapper').fadeOut(300);

                        //render report
                        requestId = response.requestId;
                        sessionId = response.sessionId;
                        renderReport();

                        $('#step-search').slick('slickGoTo', page.report, false);
                    },
                    error: function(xhr) {
                        toastr.error("Please reload the page to try again. If you keep seeing this error please contact a Gallagher Consultant today.", "We encountered an error");
                    },
                    complete: function (xhr, status) { }
                });
            } else {
                toastr.error("Please enter all required fields", "Missing Fields");
                //alert('Please enter all required fields');
            }
        });        

        /*prepare json for sending to server*/
        function prepareData() {
            if (sessionId != null) {
                dataToSend.sessionId = sessionId;
            }
            dataToSend.industryName = selectedIndustry.name;
            dataToSend.industryId = selectedIndustry.industryId;
            dataToSend.revenue = +$('#annual-sales').val().replace(/,/g, '');
            dataToSend.employeeCount = +$('#num-employees').val().replace(/,/g, '');
            dataToSend.locationCount = +$('#num-locations').val().replace(/,/g, '');
            dataToSend.firstName = $('#first').val();
            dataToSend.lastName = $('#last').val();
            dataToSend.email = $('#email').val();
            dataToSend.company = $('#company').val();
            dataToSend.agentEmail = $('#agent-email').val();
        }

        function renderReport() {
            //sample data from cyberResult @file sampleData.js
            var $annulSales = $('#r-annual-sales'), $industry = $('#r-industry'), $eeCount = $('#r-num-employees');
            var $seats = $('#seats');
            lowCurrency = convertToCurrency(parseInt(cyberResult.lowEndRisk), 1, '$');
            midCurrency = convertToCurrency(parseInt(cyberResult.midRangeRisk), 1, '$');
            highCurrency = convertToCurrency(parseInt(cyberResult.highEndRisk), 1, '$');

            var $summaryTitle = $('#summary-title');
            var $highExposure = $('#high-exposure'), $midExposure = $('#mid-exposure'), $lowExposure = $('#low-exposure');
            var $claim1Val = $('#claim1-val'), $claim2Val = $('#claim2-val'), $claim3Val = $('#claim3-val');
            var $tabImg1 = $('#tab-img1'), $tabImg2 = $('#tab-img2'), $tabImg3 = $('#tab-img3');
            var $tabIncurredVal = [$('#tab-header1').find('.incurred-value'), $('#tab-header2').find('.incurred-value'), $('#tab-header3').find('.incurred-value')];
            var $tabDetail = [$('#tab-content1').find('.detail'), $('#tab-content2').find('.detail'), $('#tab-content3').find('.detail')];

            var $summary = $('#summary');
            var $numClaims = $('#num-claims'), $percentageClaims = $('#percentage-claims');

            var summaryStr;
            summaryStr = "";
            //$summary.html(summaryStr);
            $summaryTitle.html(dataToSend.company);

            $claim1Val.html(convertToCurrency(parseInt(cyberResult.claims[0].totalIncurred), 1, '$'));
            $claim2Val.html(convertToCurrency(parseInt(cyberResult.claims[1].totalIncurred), 1, '$'));
            $claim3Val.html(convertToCurrency(parseInt(cyberResult.claims[2].totalIncurred), 1, '$'));

            $highExposure.html(convertToCurrency(parseInt(cyberResult.highEndRisk), 1, '$'));
            $midExposure.html(convertToCurrency(parseInt(cyberResult.midRangeRisk), 1, '$'));
            $lowExposure.html(convertToCurrency(parseInt(cyberResult.lowEndRisk), 1, '$'));

            $('#high-level-label').html(convertToCurrency(parseInt(cyberResult.highEndRisk), 1, '$'));
            $('#mid-level-label').html(convertToCurrency(parseInt(cyberResult.midRangeRisk), 1, '$'));
            $('#low-level-label').html(convertToCurrency(parseInt(cyberResult.lowEndRisk), 1, '$'));

            /* if data return include different type of claims, use this one*/
            /*$tabImg1.addClass("gal-" + cyberResult.claims[0].accidentType);
            $tabImg2.addClass("gal-" + cyberResult.claims[1].accidentType);
            $tabImg3.addClass("gal-" + cyberResult.claims[2].accidentType);*/

            /* if claims type is based on whatever the use choose the industry, use this one */
            $tabImg1.addClass("gal-" + selectedIndustry.cssClass);
            $tabImg2.addClass("gal-" + selectedIndustry.cssClass);
            $tabImg3.addClass("gal-" + selectedIndustry.cssClass);

            cyberResult.claims.forEach(function (claim, index) {
                $tabIncurredVal[index].html(convertToCurrency(parseInt(claim.totalIncurred), 0, '$', 1));
                $tabDetail[index].html(addReadMore(claim.detail, 200, index));
            });
            $numClaims.html(cyberResult.claimCountGreaterOneMillion);
            $percentageClaims.html(cyberResult.claimPercentageOverHundredThousand + '%');

            $annulSales.html(convertToCurrency(parseInt(dataToSend.revenue), 1, '$'));
            $industry.html(dataToSend.industryName);
            $eeCount.html(convertToCurrency(parseInt(dataToSend.employeeCount), 0, '', 1));

            $seats.html(convertToCurrency(cyberResult.enterpriseSeats, 0, '', 1));

            chartCanvas = new CreateChart('chart-canvas');
            //create this dummy-canvas for image when user want to send comparison, this canvas won't response to viewport size
            new CreateChart('dummy-canvas');
        }

        $('#btn-back-search').on('click', function (e) {
            e.preventDefault();
            destroyChart();
            $('#step-search').slick('slickGoTo', page.industry, false);
        });

        /*switch toggle (show / hide exposure) start*/
        $('#onoff-input').on('click', function (e) {
            showClaims = $(this).is(':checked');
            if (showClaims == true) {
                $('.alert-light > span:nth-child(1)').removeClass('selected');
                $('.alert-light > span:nth-child(2)').addClass('selected');
                $('#claims-tabs-wrapper').slideDown(function () {
                    $('#step-search').slick('setPosition');
                });
            } else {
                $('.alert-light > span:nth-child(1)').addClass('selected');
                $('.alert-light > span:nth-child(2)').removeClass('selected');
                $('#claims-tabs-wrapper').slideUp(function () {
                    $('#step-search').slick('setPosition');
                });
            }
            showHideBarDot();
        });
        /*switch toggle end*/

        /*claims tabs start*/
        $claimsTabs = $('#claims-tabs .tab');
        $claimsContents = $('#claims-tabs-content');
        $('#claims-tabs').on('click', '.tab', function (e) {
            var refIndex = $claimsTabs.index($(this));
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
            $claimsContents.children().each(function (idx, val) {
                idx == refIndex ? $(this).addClass('active') : $(this).removeClass('active');
            });
        });
        $('#claims-tabs-content').on('click', '.read-more', function () {
            var refIndex = $(this).data('ref');
            var tempStr = cyberResult.claims[refIndex].detail + '...<span class="show-less" data-ref="' + refIndex + '">[show less]</span>';
            $(this).parent().html(tempStr);
            $('#step-search').slick('setPosition');
        });
        $('#claims-tabs-content').on('click', '.show-less', function () {
            var refIndex = $(this).data('ref');
            $(this).parent().html(addReadMore(cyberResult.claims[refIndex].detail, 200, refIndex));
            $('#step-search').slick('setPosition');
        });
        /*claims tabs end*/

        $("#btn-email-comparison").on("click",
            function () {
                if (!$(this).attr("disabled")) {

                    var chartCanvas = document.querySelector("#dummy-canvas canvas");
                    var chartDataUrl = chartCanvas.toDataURL("image/png");

                    $(this).attr("disabled", "disabled");

                    $("#btn-email-comparison").text("Processing...");

                    $.ajax({
                        type: "POST",
                        contentType: "application/json",
                        url: baseAPIURL + "/api/CyberRisk/request/" + requestId + "/email",
                        data: JSON.stringify({
                            imageUrl: chartDataUrl
                        }),
                        success: function (response) {
                            if (response.isSuccess === true) {
                                toastr.info("Thank You!<br/><br/>Your responses have been submitted and your report will be emailed to you shortly.<br/><br/>For more information, please contact a Gallagher Consultant today!");
                            } else {
                                toastr.error("There was an error while trying to send your report. Please contact a Gallagher Consultant today.");
                            }
                        },
                        error: function (xhr) {
                            toastr.error("There was an error while trying to send your report. Please contact a Gallagher Consultant today.");
                            console.log(xhr);
                        },
                        complete: function () {
                            $("#btn-email-comparison").removeAttr("disabled");
                            $("#btn-email-comparison").text("Email Cyber Risk Results");
                        }
                    });
                }

            });

        buildSlide();
    });
})(jQuery);