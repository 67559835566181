var industryArray,
    exposureBandArray,
    umbrellaResult,
    emailIsValid = false,
    firstNameIsValid = false,
    lastNameIsValid = false,
    companyIsValid = false,
    agentIsValid = true,
    subCatIsValid = true,
    annualSalesIsValid = false,
    numberOfEmployeesIsValid = false,
    numberOfLocationsIsValid = false;

function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

function isValidName(input) {
    var regex = /^[a-zA-Z ,.'-]+$/;
    return regex.test(input);
}

function isValidAgent(input) {
    var regex = /^[a-zA-Z ,.'-]+$|^$/;
    return regex.test(input);
}

function isValidCompany(input) {
    var regex = /^[a-zA-Z0-9 ,.'&-]+$/;
    return regex.test(input);
}

function validateEmail() {
    var emailAddress = $("#email").val().trim();
    emailIsValid = false;
    var element = "#email-validation-message";

    if (emailAddress.length === 0) {
        showErrorMessage(element, "Email address is required");
        return;
    }

    if (!isEmail(emailAddress)) {
        showErrorMessage(element, "Please enter your email address in format: yourname@example.com");
        return;
    }

    $.ajax({
        contentType: "application/json",
        url: baseAPIURL + "/api/General/ValidateEmail",
        type: "POST",
        data: JSON.stringify({ 'emailAddress': emailAddress }),
        success: function (response) {
            if (!response.isValid) {
                showErrorMessage(element, response.defaultInvalidEmailResponse);
                return;
            } 

            emailIsValid = true;
            hideErrorMessage(element);
        },
        error: function (xhr) {
            console.log(xhr);
            toastr.error("Please reload the page to try again. If you keep seeing this error please contact a Gallagher Consultant today.", "We encountered an error");
        },
        complete: function () { }
    });
}

function validateFirstName() {
    var first = $("#first").val().trim();
    firstNameIsValid = false;
    var element = "#first-validation-message";

    if (first.length === 0) {

        showErrorMessage(element, "First name is required");
        return;
    }

    if (!isValidName(first)) {
        showErrorMessage(element, "Please enter a valid first name");
        return;
    }

    firstNameIsValid = true;
    hideErrorMessage(element);
}

function validateLastName() {
    var last = $("#last").val().trim();
    lastNameIsValid = false;
    var element = "#last-validation-message";

    if (last.length === 0) {

        showErrorMessage(element, "Last name is required");
        return;
    }

    if (!isValidName(last)) {
        showErrorMessage(element, "Please enter a valid last name");
        return;
    }

    lastNameIsValid = true;
    hideErrorMessage(element);
}

function validateCompany() {
    var company = $("#company").val().trim();
    companyIsValid = false;
    var element = "#company-validation-message";

    if (company.length === 0) {

        showErrorMessage(element, "Company is required");
        return;
    }

    if (!isValidName(company)) {
        showErrorMessage(element, "Please enter a valid company");
        return;
    }

    companyIsValid = true;
    hideErrorMessage(element);
}

function validateAgent() {
    var agent = $("#agent-email").val().trim();
    agentIsValid = false;
    var element = "#agent-validation-message";

    if (agent.length === 0) {
        agentIsValid = true;
        hideErrorMessage(element);
    }

    if (!isValidAgent(agent)) {
        showErrorMessage(element, "Please enter a valid agent name");
        return;
    }

    agentIsValid = true;
    hideErrorMessage(element);
}

function validateAnnualSales() {
    var sales = $("#annual-sales").val().trim().replace(/,/g, '');;
    annualSalesIsValid = false;
    var element = "#annual-sales-validation-message";

    if (sales.length === 0) {

        showErrorMessage(element, "Annual sales is required");
        return;
    }

    if (isNaN(sales)) {
        showErrorMessage(element, "Please enter valid annual sales");
        return;
    }

    annualSalesIsValid = true;
    hideErrorMessage(element);
}

function validateNumberOfEmployees() {
    var employees = $("#num-employees").val().trim().replace(/,/g, '');;
    numberOfEmployeesIsValid = false;
    var element = "#num-employees-validation-message";

    if (employees.length === 0) {

        showErrorMessage(element, "Number of employees is required");
        return;
    }

    if (isNaN(employees)) {
        showErrorMessage(element, "Please enter valid number of employees");
        return;
    }

    numberOfEmployeesIsValid = true;
    hideErrorMessage(element);
}

function validateNumberOfLocations() {
    var locations = $("#num-locations").val().trim().replace(/,/g, '');
    numberOfLocationsIsValid = false;
    var element = "#num-locations-validation-message";

    if (locations.length === 0) {
        showErrorMessage(element, "Number of locations is required.");
        return;
    }

    if (isNaN(locations)) {
        showErrorMessage(element, "Please enter a valid number of locations.");
        return;
    }

    numberOfLocationsIsValid = true;
    hideErrorMessage(element);
}

function showErrorMessage(elementId, message) {
    $(elementId).show();
    $(elementId).text(message);
    $("#step-search").find(".slick-slide").height("auto");
    $("#step-search").slick("setOption", "", "", true);
}

function hideErrorMessage(elementId) {
    $(elementId).hide();
    $("#step-search").find(".slick-slide").height("auto");
    $("#step-search").slick("setOption", "", "", true);
}